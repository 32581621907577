import { configureStore, createListenerMiddleware } from "@reduxjs/toolkit";

import settings from "./settings";
import avatar from "./avatar";
import weapons from "./weapons";
import { StorageManager } from "../lib/storage/storage_manager";

const middleware = createListenerMiddleware();
// middleware.startListening({
//   actionCreator: settingsSlice.actions.setDLCFilter,
//   effect: async (action, store: any) => {
//     console.log("action", action, store);
//     // store.dispatch(addWithoutSave(store.getState().avatar.appearance));
//   },
// });

const store = configureStore({
  reducer: {
    settings,
    avatar,
    weapons,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(middleware.middleware),
});

store.subscribe(() => {
  const state = store.getState();
  console.log("state", state);
  StorageManager.set("settings", state.settings);
  StorageManager.set("avatar", state.avatar);
  StorageManager.set("weapons", state.weapons);
});

export default store;
