import { createSlice } from "@reduxjs/toolkit";
import { StorageManager } from "../lib/storage/storage_manager";

export type WeaponPosition = "left" | "right";

export type WeaponsSaveObject = { [key in WeaponPosition]: string | null };

export interface WeaponsState {
  weapons: WeaponsSaveObject;
  undo: WeaponsSaveObject[];
  redo: WeaponsSaveObject[];
}

const initState = (): WeaponsState => {
  const state = StorageManager.get("weapons") as WeaponsState;
  if (state && state.weapons && state.undo && state.redo) {
    return state;
  }
  return {
    weapons: {
      left: null,
      right: null,
    },
    undo: [],
    redo: [],
  };
};

const initialState = initState() satisfies WeaponsState as WeaponsState;

export const weaponsSlice = createSlice({
  name: "weapons",
  initialState,
  reducers: {
    addWeapon: (
      state,
      {
        payload,
      }: { payload: { id: string; position: WeaponPosition }; type: string }
    ) => {
      const { id, position } = payload as {
        id: string;
        position: WeaponPosition;
      };

      if (state.weapons[position] && state.weapons[position] === id) {
        state.weapons[position] = null;
      } else {
        state.weapons[position] = payload.id;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { addWeapon } = weaponsSlice.actions;

export default weaponsSlice.reducer;
