import { useEffect, useState } from "react";
import "./App.scss";
import MainContainer from "./ui/MainContainer/MainContainer";
import html2canvas from "html2canvas";
import { StorageManager } from "./lib/storage/storage_manager";
import { useDispatch, useSelector } from "react-redux";
import { SettingsState, Banner } from "./store/settings";
import VersionElement, { version } from "./ui/VersionElement/VersionElement";
import { add, clearActive } from "./store/avatar";
import { Appearance, ItemManager } from "./lib/appearance/item_manager";
import { createBitmap, createBitmapWithBackground } from "./lib/utils";
import { AvatarState } from "./store/avatar";
import { CosmeticManager } from "./lib/appearance/cosmetic_manager";
import BannerElement from "./ui/AvatarArea/Banner/BannerElement";
import WeaponsArea from "./ui/AvatarArea/WeaponsArea/WeaponsArea";
import { WeaponsSaveObject, WeaponsState } from "./store/weapons";
import SocialContainer from "./ui/Sozial/SocialContainer";
import AnalyticsToast from "./ui/Toasts/AnalyticsToast/AnalyticsToast";

function App() {
  const dispatch = useDispatch();
  const { appearance }: AvatarState = useSelector((state: any) => state.avatar);
  const { weapons }: WeaponsState = useSelector((state: any) => state.weapons);

  const {
    showAnimation,
    showLegs,
    showWeapons,
    showShadow,
    background,
    docking,
    banner,
  }: SettingsState = useSelector((state: any) => state.settings);

  const [versionInfoOpen, setVersionInfoOpen] = useState<Boolean>(false);

  const [selected, setSelected] = useState<string[]>(appearance ?? []);

  const [_weapons, setWeapons] = useState<WeaponsSaveObject>(
    StorageManager.get("weapons") ?? []
  );

  const [savedToastVisible, setSavedToastVisible] = useState<Boolean>(false);

  //todo
  useEffect(() => {
    setWeapons(weapons);
  }, [weapons]);

  useEffect(() => {
    setSelected(appearance);
  }, [appearance]);

  useEffect(() => {
    if (!savedToastVisible) return;

    const toast = document.getElementById("saved");
    if (!toast) return;

    toast.classList.add("show");
    setTimeout(() => {
      toast.classList.remove("show");
      setSavedToastVisible(false);
    }, 3000);
  }, [savedToastVisible]);

  const downloadURI = (uri: string, name: string) => {
    var link = document.createElement("a");

    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
  };

  const createBitmapFromCanvas = async (callback: (img: string) => {}) => {
    let element: HTMLDivElement | null = document.getElementById(
      "AvatarContainer"
    ) as HTMLDivElement | null;
    if (!element) return;

    element.classList.remove("animate");

    const calcWidth = () => {
      if (banner !== Banner.None) {
        if (_weapons.left || _weapons.right) {
          return 440;
        }
        return 410;
      }
      if (_weapons.left || _weapons.right) {
        return 420;
      }
      return 380;
    };

    html2canvas(element, {
      width: calcWidth(),
      height: 380,
      backgroundColor: null,
    }).then(async function (canvas) {
      if (showAnimation && element) element.classList.add("animate");
      const img = !background
        ? await createBitmap(canvas)
        : await createBitmapWithBackground(
            canvas,
            "assets/" + background.image
          );
      if (!img) return;
      callback(img);
    });
  };

  const downloadAsImage = () => {
    createBitmapFromCanvas(async (img: string) => {
      downloadURI(img, "my-brotato-avatar.png");
    });
  };

  const clipboardImage = () => {
    createBitmapFromCanvas(async (img: string) => {
      const data = await fetch(img);
      const blob = await data.blob();

      await navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob,
        }),
      ]);
      setSavedToastVisible(true);
    });
  };

  return (
    <div
      className="App"
      style={{
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        [background ? "background" : ""]: `url(assets/${background?.image})`,
      }}
      onMouseMove={(e) => {
        if (!showWeapons) return;

        // let box: HTMLElement | null = document.querySelector(".dagger");
        // if (!box) return;

        // let boxCenter = {
        //   x: window.innerWidth / 2,
        //   y: window.innerHeight / 2,
        // };
        // let angle =
        //   Math.atan2(e.pageX - boxCenter.x, -(e.pageY - boxCenter.y)) *
        //     (180 / Math.PI) -
        //   90;
        // box.style.transform = `rotate(${angle}deg)`;
      }}
    >
      <AnalyticsToast />
      {savedToastVisible && (
        <div id="saved" className="toast-notification">
          Copied to clipboard!
        </div>
      )}
      <SocialContainer docking={docking} />
      <div
        className={`bortato-avatar-container ${docking}`}
        id="AvatarContainer"
      >
        {banner !== Banner.None && <BannerElement />}
        <div
          className={`animate-container ${showAnimation ? "animate" : ""}`}
          style={{}}
        >
          {<WeaponsArea weapons={_weapons} />}
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            {selected.map((item_key) => {
              const item =
                ItemManager.instance.getItemById(item_key) ??
                CosmeticManager.instance.getItemById(item_key);
              return item?.appearance.map((appearance: Appearance) => {
                return (
                  <img
                    key={appearance.path}
                    src={appearance.path}
                    className="avatar-item"
                    alt="hhallo"
                    style={{
                      zIndex: appearance.depth,
                    }}
                  />
                );
              });
            })}
            {showLegs && (
              <img
                key="assets/legs.png"
                src="assets/legs.png"
                alt="legs"
                className="avatar-item"
                style={{
                  zIndex: "var(--zi-legs)",
                }}
              />
            )}
            {showShadow && <div className="avatar-shadow"></div>}
          </div>
        </div>
      </div>
      {versionInfoOpen && (
        <div className="popup">
          <div className="background"></div>
          <div
            className="container"
            style={{
              maxHeight: "50%",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 10,
                fontSize: 20,
                cursor: "pointer",
                height: "fit-content",
              }}
              onClick={() => {
                setVersionInfoOpen(false);
              }}
            >
              x
            </div>
            <span>ChangeLog</span>
            <VersionElement />
          </div>
        </div>
      )}
      <span
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          fontSize: 15,
          color: "gray",
          fontFamily: "Arial",
          fontWeight: "bold",
          padding: 10,
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={() => {
          setVersionInfoOpen(true);
        }}
      >
        {version}
        {/* {ItemManager.instance.itemListToHash(
          selected
            .map((item_key) => ItemManager.instance.getItemById(item_key))
            .filter((item) => item) as Item[]
        )} */}
      </span>
      <MainContainer
        className={docking}
        onShuffle={(keys: string[]) => {
          dispatch(add(keys));
        }}
        onClear={() => {
          dispatch(clearActive());
          setSelected([]);
        }}
        onDownload={() => {
          downloadAsImage();
        }}
        onClipbaord={() => {
          clipboardImage();
        }}
        selected={selected}
        weapons={_weapons}
      />
    </div>
  );
}

export default App;
