import { createSlice } from "@reduxjs/toolkit";
import { StorageManager } from "../lib/storage/storage_manager";

export interface AvatarState {
  appearance: string[];
  history: string[][];
  index: number;
}

const initialState = (StorageManager.get("avatar") ?? {
  appearance: [],
  history: [],
  index: -1,
}) satisfies AvatarState as AvatarState;

export const avatarSlice = createSlice({
  name: "avatar",
  initialState,
  reducers: {
    undo: (state) => {
      if (state.index - 1 < 0) return;
      state.index = state.index - 1;
      state.appearance = state.history[state.index];
    },
    redo: (state) => {
      if (state.index >= state.history.length - 1) return;
      state.index = state.index + 1;
      state.appearance = state.history[state.index];
    },
    add: (state, action) => {
      if (state.appearance.length === 0) {
        state.history = [];
        state.index = -1;
      } else if (state.index < state.history.length - 1) {
        state.history = state.history.slice(0, state.index + 1);
      }
      state.history.push(state.appearance);
      state.index = state.index + 1;
      state.appearance = action.payload;
    },
    addWithoutSave: (state, action) => {
      state.appearance = action.payload;
    },
    addAppearance: (state, { payload }) => {
      if (state.appearance.includes(payload)) {
        state.appearance = state.appearance.filter((a) => a !== payload);
      } else state.appearance.push(payload);
    },
    addCosmetic: (state, { payload }) => {
      if (state.appearance.includes(payload)) {
        state.appearance = state.appearance.filter((a) => a !== payload);
      } else state.appearance.push(payload);
    },
    clearActive: (state) => {
      state.appearance = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  undo,
  redo,
  add,
  addWithoutSave,
  addAppearance,
  addCosmetic,
  clearActive,
} = avatarSlice.actions;

export default avatarSlice.reducer;
